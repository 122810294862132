/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
// @mui
import { Avatar, Card, CardContent, CardHeader, Stack, Tab, Table, TableBody, TableCell, TableRow, Tabs, Typography } from '@mui/material';
// components
import Link from 'next/link';
import Iconify from '../../components/Iconify';
import TextMaxLine from '../../components/TextMaxLine';
import useLocales from '../../hooks/useLocales';
import { getTopUser, setUserSearch } from '../../redux/slices/hackerthon/hackerthon.user';
import { useDispatch, useSelector } from '../../redux/store';
import { fPercent } from '../../utils/formatNumber';
import { fDates } from '../../utils/formatTime';
// ----------------------------------------------------------------------

export default function SidebarRankingUser() {
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const { topUsers, search: searchUser } = useSelector((state) => state.user);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(getTopUser());
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchUser]);

  const TABS = [
    { id: 0, label: translate("ranking.topserver") },
    { id: 1, label: translate("ranking.top30days") },
    { id: 2, label: translate("ranking.top7days") },
  ];

  const [tab, setTab] = useState(0);
  const handleChangeTab = (_, newValue) => {
    if (newValue === 0) {
      dispatch(setUserSearch({ ...searchUser, page: 0, filterBys: { ...searchUser.filterBys, startAt: null, endAt: null } }));
    } else if (newValue === 1) {
      let _now = new Date();
      let lastDate = new Date(_now.getFullYear(), _now.getMonth() - 1, _now.getDate());
      dispatch(setUserSearch({ ...searchUser, page: 0, filterBys: { ...searchUser.filterBys, startAt: fDates(lastDate), endAt: fDates(_now) } }));
    } else {
      let _now = new Date();
      let lastDate = new Date(_now.getFullYear(), _now.getMonth(), _now.getDate() - 7);
      dispatch(setUserSearch({ ...searchUser, page: 0, filterBys: { ...searchUser.filterBys, startAt: fDates(lastDate), endAt: fDates(_now) } }));
    }
    setTab(newValue);
  };

  return (
    <Card>
      <CardHeader title={translate("ranking.rank")} sx={{ mb: 2 }} />
      <CardContent sx={{ p: 0 }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          textColor="secondary" variant="fullWidth" indicatorColor="secondary"
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {TABS.map((tab) => (
            <Tab disableRipple key={tab.id} value={tab.id} label={tab.label} />
          ))}
        </Tabs>
        <Table>
          <TableBody>
            {topUsers.map((row, index) => (
              <TableRow key={index} >
                <TableCell sx={{ py: 1.5 }}>
                  <Link href={`/user/${row.id}`} passHref>
                    <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ cursor: 'pointer' }}>
                      <Avatar alt={row.displayName} src={row.photoURL} sx={{ width: 36, height: 36 }} />
                      <Stack>
                        <TextMaxLine variant="subtitle2" line={1} noWrap>{row.displayName}</TextMaxLine>
                        <Typography variant='caption' color={"text.disabled"} >{row.score}đ ({fPercent(row.acceptedNo * 100 / row.answerNo)})</Typography>
                      </Stack>
                    </Stack>
                  </Link>
                </TableCell>
                <TableCell sx={{ py: 1 }}>
                  {index == 0 ? <Iconify icon={"material-symbols:rewarded-ads"} sx={{ width: 30, height: 30, color: '#fccb2b' }} /> : (
                    <Typography variant='subtitle2'>#{index + 1} </Typography>)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

