import { format, formatDistanceToNow, getTime, parse } from 'date-fns';
import { enUS, vi } from 'date-fns/locale';
// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}
export function fDates(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function parseDateTimeSuffix(dateString) {
  return parse(dateString, 'dd/MM/yyyy HH:mm', new Date());
}

export function fToNow(date, currentLang = 'vi') {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    includeSeconds: true,
    locale: currentLang === 'vi' ? vi : enUS,
  });
}
